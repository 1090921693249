.modal-custom {
    .modal-dialog {
        .modal-content {
            border-radius: 15px;
            .modal-header-close {
                padding-top: 10px;
                button {
                    color: #000;
                }
            }
            .modal-body {
                .form-group {
                    padding-bottom: 10px;
                }
                .social-form {
                    background-color: #efefef;
                    width: 35px;
                    margin: 5px;
                    border-radius: 50%;
                    height: 35px;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 1px solid #ffffff;
                    transition: all 0.3s ease;

                    box-shadow: 0 0 2px 1px rgb(0 0 0 / 16%);
                    &:hover{
                        border: 1px solid #0c2041;

                    }
                }
                .or {
                    text-align: center;
                    padding: 0;
                    font-weight: 700;
                }
            }
            .form {
                label {
                    font-size: 13px;
                    font-weight: 600;
                    margin-bottom: 5px;
                    &.error {
                        color: #c40000;
                        font-size: 12px;
                        margin: 0;
                        line-height: 15px;
                        font-weight: unset;
                    }
                }
                input {
                    box-shadow: 0 0 2px 1px rgb(0 0 0 / 16%);
                    border-radius: 4px;
                    font-size: 13px;
                }
                .register {
                    button {
                        border: none;
                        background-color: $green-main;
                        border-radius: 4px;
                        padding: 0.375rem 15px;
                        color: #fff;
                        span {
                            font-size: 13px;
                        }
                        i {
                            vertical-align: middle;
                        }
                    }
                }
            }
        }
        &.login{
            .modal-content{

            }
            .form-group{
                margin-bottom: 0;
            }
        }
    }
}

.modal-map {
    .modal-dialog {
        .modal-content {
            border-radius: 15px;
            button {
                position: absolute;
                top: 3%;
                right: 3%;
                z-index: 1;
                font-size: 30px;
            }
            .modal-body {
                height: 300px;
                iframe {
                    border-radius: 15px;
                }
            }
        }
    }
}

.modal-otp {
    .modal-dialog {
        .modal-content {
            .modal-body {
                .form {
                    form {
                        display: block;
                        padding-top: 10px;
                        .form-group {
                            padding: 0px 13px;
                            input {
                                height: 50px;
                                font-size: 25px;
                                text-align: center;
                            }
                        }
                        .Continue {
                            padding: 30px 0;
                        }
                    }
                }
            }
        }
    }
}

#exampleModalPayment {
    .modal-dialog {
        .modal-content {
            .modal-body {
                padding: 0 30px 30px 30px;
                .blog {
                    box-shadow: 0 5px 5px 5px rgb(0 0 0 / 16%);
                    padding: 20px 0;
                    border-radius: 15px;
                    .col-3 {
                        .img {
                            width: 70px;
                            height: 70px;
                            overflow: hidden;
                        }
                        padding: 0 7px 0 15px;
                    }
                    .col-9 {
                        padding: 0 15px 0 7px;
                        h6,
                        p {
                            margin-bottom: 0;
                        }
                    }
                }
                .pay-method {
                    margin-top: 20px;
                    ul {
                        li {
                            a {
                                padding: 10px;
                                img {
                                    width: 70px;
                                }
                            }
                        }
                    }
                    .form {
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:992px) {
    //.modal-custom {
    //    .container {
    //        width: 80% !important;
    //    }
    //}
}

@media only screen and (max-width:576px) {
    .modal-custom {
        .modal-body {
            //img {
            //    width: 15px ;
            //    margin-right: 5px ;
            //}
            span {
                font-size: 11px ;
            }
            textarea {
                height: 60px;
            }
        }
    }
}
