@font-face {
  font-family: "title-en";
  src: url("../fonts/LEMONMILK-Bold.woff2") format("woff2"), url("../fonts/LEMONMILK-Bold.woff") format("woff"), url("../fonts/LEMONMILK-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "text-bold-en";
  src: url("../fonts/LEMONMILK-RegularItalic.woff2") format("woff2"), url("../fonts/LEMONMILK-RegularItalic.woff") format("woff"), url("../fonts/LEMONMILK-RegularItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "text-en";
  src: url("../fonts/Gotham-Book.woff2") format("woff2"), url("../fonts/Gotham-Book.woff") format("woff"), url("../fonts/Gotham-Book.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "title-kh";
  src: url("../fonts/Dangrek.woff2") format("woff2"), url("../fonts/Dangrek.woff") format("woff"), url("../fonts/Dangrek.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "text-bold-kh";
  src: url("../fonts/Kantumruy-Bold.woff2") format("woff2"), url("../fonts/Kantumruy-Bold.woff") format("woff"), url("../fonts/Kantumruy-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "text-light-kh";
  src: url("../fonts/Kantumruy-Light.woff2") format("woff2"), url("../fonts/KantumruyLight.woff") format("woff"), url("../fonts/KantumruyLight.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
.toast-title {
  font-weight: 700;
}

.toast-message {
  -ms-word-wrap: break-word;
  word-wrap: break-word;
}

.toast-message a, .toast-message label {
  color: #FFF;
}

.toast-message a:hover {
  color: #CCC;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: 700;
  color: #FFF;
  -webkit-text-shadow: 0 1px 0 #fff;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
  line-height: 1;
}

.toast-close-button:focus, .toast-close-button:hover {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: alpha(opacity=40);
}

.rtl .toast-close-button {
  left: -0.3em;
  float: left;
  right: 0.3em;
}

button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
  -webkit-appearance: none;
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

#toast-container {
  position: fixed;
  z-index: 999999;
  pointer-events: none;
}

#toast-container * {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

#toast-container > div {
  position: relative;
  pointer-events: auto;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  -moz-box-shadow: 0 0 12px #999;
  -webkit-box-shadow: 0 0 12px #999;
  box-shadow: 0 0 12px #999;
  color: #FFF;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80);
}

#toast-container > div.rtl {
  direction: rtl;
  padding: 15px 50px 15px 15px;
  background-position: right 15px center;
}

#toast-container > div:hover {
  -moz-box-shadow: 0 0 12px #000;
  -webkit-box-shadow: 0 0 12px #000;
  box-shadow: 0 0 12px #000;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
  cursor: pointer;
}

#toast-container > .toast-info {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGwSURBVEhLtZa9SgNBEMc9sUxxRcoUKSzSWIhXpFMhhYWFhaBg4yPYiWCXZxBLERsLRS3EQkEfwCKdjWJAwSKCgoKCcudv4O5YLrt7EzgXhiU3/4+b2ckmwVjJSpKkQ6wAi4gwhT+z3wRBcEz0yjSseUTrcRyfsHsXmD0AmbHOC9Ii8VImnuXBPglHpQ5wwSVM7sNnTG7Za4JwDdCjxyAiH3nyA2mtaTJufiDZ5dCaqlItILh1NHatfN5skvjx9Z38m69CgzuXmZgVrPIGE763Jx9qKsRozWYw6xOHdER+nn2KkO+Bb+UV5CBN6WC6QtBgbRVozrahAbmm6HtUsgtPC19tFdxXZYBOfkbmFJ1VaHA1VAHjd0pp70oTZzvR+EVrx2Ygfdsq6eu55BHYR8hlcki+n+kERUFG8BrA0BwjeAv2M8WLQBtcy+SD6fNsmnB3AlBLrgTtVW1c2QN4bVWLATaIS60J2Du5y1TiJgjSBvFVZgTmwCU+dAZFoPxGEEs8nyHC9Bwe2GvEJv2WXZb0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=) !important;
}

#toast-container > .toast-error {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=) !important;
}

#toast-container > .toast-success {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==) !important;
}

#toast-container > .toast-warning {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGYSURBVEhL5ZSvTsNQFMbXZGICMYGYmJhAQIJAICYQPAACiSDB8AiICQQJT4CqQEwgJvYASAQCiZiYmJhAIBATCARJy+9rTsldd8sKu1M0+dLb057v6/lbq/2rK0mS/TRNj9cWNAKPYIJII7gIxCcQ51cvqID+GIEX8ASG4B1bK5gIZFeQfoJdEXOfgX4QAQg7kH2A65yQ87lyxb27sggkAzAuFhbbg1K2kgCkB1bVwyIR9m2L7PRPIhDUIXgGtyKw575yz3lTNs6X4JXnjV+LKM/m3MydnTbtOKIjtz6VhCBq4vSm3ncdrD2lk0VgUXSVKjVDJXJzijW1RQdsU7F77He8u68koNZTz8Oz5yGa6J3H3lZ0xYgXBK2QymlWWA+RWnYhskLBv2vmE+hBMCtbA7KX5drWyRT/2JsqZ2IvfB9Y4bWDNMFbJRFmC9E74SoS0CqulwjkC0+5bpcV1CZ8NMej4pjy0U+doDQsGyo1hzVJttIjhQ7GnBtRFN1UarUlH8F3xict+HY07rEzoUGPlWcjRFRr4/gChZgc3ZL2d8oAAAAASUVORK5CYII=) !important;
}

#toast-container.toast-bottom-center > div, #toast-container.toast-top-center > div {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

#toast-container.toast-bottom-full-width > div, #toast-container.toast-top-full-width > div {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.toast {
  background-color: #030303;
}

.toast-success {
  background-color: #51A351;
}

.toast-error {
  background-color: #BD362F;
}

.toast-info {
  background-color: #2F96B4;
}

.toast-warning {
  background-color: #F89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000;
  opacity: 0.4;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: alpha(opacity=40);
}

@media all and (max-width: 240px) {
  #toast-container > div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }
  #toast-container > div.rtl {
    padding: 8px 50px 8px 8px;
  }
  #toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
  #toast-container .rtl .toast-close-button {
    left: -0.2em;
    right: 0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  #toast-container > div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }
  #toast-container > div.rtl {
    padding: 8px 50px 8px 8px;
  }
  #toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
  #toast-container .rtl .toast-close-button {
    left: -0.2em;
    right: 0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  #toast-container > div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
  #toast-container > div.rtl {
    padding: 15px 50px 15px 15px;
  }
}
@font-face {
  font-family: "title-en";
  src: url("../fonts/LEMONMILK-Bold.woff2") format("woff2"), url("../fonts/LEMONMILK-Bold.woff") format("woff"), url("../fonts/LEMONMILK-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "text-bold-en";
  src: url("../fonts/LEMONMILK-RegularItalic.woff2") format("woff2"), url("../fonts/LEMONMILK-RegularItalic.woff") format("woff"), url("../fonts/LEMONMILK-RegularItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "text-en";
  src: url("../fonts/Gotham-Book.woff2") format("woff2"), url("../fonts/Gotham-Book.woff") format("woff"), url("../fonts/Gotham-Book.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "title-kh";
  src: url("../fonts/Dangrek.woff2") format("woff2"), url("../fonts/Dangrek.woff") format("woff"), url("../fonts/Dangrek.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "text-bold-kh";
  src: url("../fonts/Kantumruy-Bold.woff2") format("woff2"), url("../fonts/Kantumruy-Bold.woff") format("woff"), url("../fonts/Kantumruy-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "text-light-kh";
  src: url("../fonts/Kantumruy-Light.woff2") format("woff2"), url("../fonts/KantumruyLight.woff") format("woff"), url("../fonts/KantumruyLight.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
.navbar-area {
  position: -webkit-sticky;
  position: sticky;
  width: 100%;
  top: 0;
  background-color: #f8fafc;
  box-shadow: 0 0px 3px rgba(0, 0, 0, 0.25);
  z-index: 999;
}
.navbar-area #menu .col-3.con-logo {
  flex: 1;
  max-width: 230px;
  height: 80px;
}
.navbar-area #menu .col-3.con-logo .img-fluid.img-logo {
  max-width: 100%;
  height: 100%;
  padding: 10px 0;
}
.navbar-area #menu .col-6.center-menu {
  flex: 1;
  max-width: calc(100% - 430px);
}
.navbar-area #menu .col-6.center-menu ul.nav {
  align-items: center;
  height: 80px;
}
.navbar-area #menu .col-6.center-menu ul.nav li.nav-item {
  position: relative;
  padding: 5px 9px;
}
.navbar-area #menu .col-6.center-menu ul.nav li.nav-item.active a.nav-link {
  position: relative;
}
.navbar-area #menu .col-6.center-menu ul.nav li.nav-item.active a.nav-link::before {
  content: "";
  position: absolute;
  bottom: -3px;
  width: 100%;
  height: 2px;
  background-color: #fa695f;
}
.navbar-area #menu .col-6.center-menu ul.nav li.nav-item a {
  padding: 0;
  font-family: "text-en";
  font-weight: 600;
  font-size: 13px;
}
.navbar-area #menu .col-6.center-menu ul.nav li.nav-item a.resource ~ .dropdown-menu {
  transform: unset !important;
  top: 35px !important;
  left: -50px !important;
  min-width: 140px;
  box-shadow: -1px -3px 10px 1px rgba(0, 0, 0, 0.16);
  border: unset;
  border-radius: 8px;
  padding: 15px 10px;
}
.navbar-area #menu .col-6.center-menu ul.nav li.nav-item a.resource ~ .dropdown-menu li.active {
  background-color: #e9ecef;
}
.navbar-area #menu .col-6.center-menu ul.nav li.nav-item a.resource ~ .dropdown-menu li a {
  font-weight: 100;
  padding: 5px 15px;
}
.navbar-area #menu .col-6.center-menu ul.nav li.nav-item a.resource ~ .dropdown-menu li a span {
  padding-left: 5px;
}
.navbar-area #menu .col-6.center-menu ul.nav li.nav-item a.resource ~ .dropdown-menu::before {
  content: "";
  border-color: #707070;
  top: -3px;
  left: 45%;
  position: absolute;
  border-top: 0;
  border-right: 10px solid transparent;
  border-bottom: 5px solid #fff;
  border-left: 10px solid transparent;
}
.navbar-area #menu .col-3.right-btn {
  padding-left: 0;
  flex: 1;
  max-width: 200px;
  height: 80px;
  align-items: center;
  display: flex;
  justify-content: flex-end;
}
.navbar-area #menu .col-3.right-btn .language a.active img {
  border: 1px solid #fa695f;
  border-radius: 2px;
}
.navbar-area #menu .col-3.right-btn li.nav-item {
  margin-left: 15px;
}
.navbar-area #menu .col-3.right-btn li.nav-item.language {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.navbar-area #menu .col-3.right-btn li.nav-item.language:first-child {
  margin-left: 0;
}
.navbar-area #menu .col-3.right-btn .icon .sub-icon img {
  width: 30px;
  height: 30px;
}
.navbar-area #menu .col-3.right-btn .profile .dropdown-button img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 0;
}
.navbar-area #menu .col-3.right-btn .profile .dropdown-button span {
  margin-right: 15px;
}
.navbar-area #menu .col-3.right-btn .profile .dropdown-button::after {
  display: none;
}
.navbar-area #menu .col-3.right-btn .profile .dropdown-menu {
  transform: translate3d(86px, 68px, 0px) !important;
  box-shadow: -1px -3px 10px 1px rgba(0, 0, 0, 0.16);
  border: unset;
  border-radius: 8px;
  padding: 15px 0;
}
.navbar-area #menu .col-3.right-btn .profile .dropdown-menu::before {
  content: "";
  border-color: #707070;
  top: -3px;
  left: 45%;
  position: absolute;
  border-top: 0;
  border-right: 10px solid transparent;
  border-bottom: 5px solid #fff;
  border-left: 10px solid transparent;
}
.navbar-area #menu .col-3.right-btn .profile .dropdown-menu li a.dropdown-item {
  padding: 5px 10px;
}
.navbar-area #menu .col-3.right-btn .profile .dropdown-menu li a.dropdown-item i {
  width: 15px;
}
.navbar-area #menu .col-3.right-btn .profile .dropdown-menu li a.dropdown-item span {
  padding-left: 5px;
}
.navbar-area #menu .col-3.right-btn .profile .pro li:last-child {
  border-top: 2px solid #E2DFDF;
}
.navbar-area #menu .col-3.right-btn .profile .pro li:last-child button {
  border: none;
  width: 100%;
  background-color: unset;
  text-align: left;
  padding: 5px 0 0 10px;
  font-weight: 600;
}
.navbar-area #menu .col-3.right-btn .profile .pro li:last-child button i {
  width: 20px;
}
.navbar-area #menu .col-3.right-btn .profile .acc li:nth-child(2) {
  border-top: 2px solid #E2DFDF;
}
.navbar-area #menu .col-3.right-btn .profile .acc li:nth-child(2) button {
  border: none;
  width: 100%;
  background-color: unset;
  text-align: left;
  padding: 7px 0 0 15px;
  font-weight: 600;
}
.navbar-area #menu .col-3.right-btn a.nav-link.btn-login {
  background-color: #0c2041;
  border-radius: 4px;
  font-size: 14px;
  text-transform: capitalize;
  color: #fff;
}
.navbar-area #menu .col-3.right-btn button.btn {
  padding: 10px 25px;
  background-color: #51be95;
  border-radius: 10px;
  color: #fff;
}
.navbar-area #menu .col-3.right-btn button.btn span {
  font-size: 16px;
}
.navbar-area #menu .col-3.right-btn button.btn i {
  padding-left: 15px;
  font-size: 30px;
  vertical-align: middle;
}
.navbar-area #menu .col-3.right-btn li#nav-phone {
  display: none;
}
.navbar-area #menu .col-3.right-btn li#nav-phone i {
  background-color: rgba(12, 32, 65, 0.1607843137);
  padding: 10px 15px;
  border-radius: 4px;
  font-size: 18px;
  text-transform: capitalize;
  color: #000;
  cursor: pointer;
}
.navbar-area .show #mobile-menu {
  display: block;
  right: 15px;
  opacity: 1;
  visibility: visible;
}
.navbar-area #mobile-menu {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  right: -15%;
  top: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(242, 242, 242, 0.9215686275);
  z-index: 9999;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
.navbar-area #mobile-menu .list-menu {
  position: relative;
  margin: 10%;
  width: 80%;
  background: #e8e8e8;
  height: 80%;
  padding: 15px;
  border-radius: 20px;
  overflow-y: scroll;
}
.navbar-area #mobile-menu .list-menu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5;
}
.navbar-area #mobile-menu .list-menu::-webkit-scrollbar {
  width: 0;
  background-color: transparent;
}
.navbar-area #mobile-menu .list-menu::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.navbar-area #mobile-menu .list-menu ul.nav li.nav-item {
  position: relative;
}
.navbar-area #mobile-menu .list-menu ul.nav li.nav-item a.nav-link {
  font-size: 18px;
}
.navbar-area #mobile-menu .list-menu ul.nav li.nav-item a.nav-link.btn-login {
  background-color: #0c2041;
  color: white;
  margin-top: 15px;
  border-radius: 4px;
  width: 100%;
}
.navbar-area #mobile-menu .list-menu ul.nav li.nav-item.language {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  margin: 0 10px;
}
.navbar-area #mobile-menu .list-menu ul.nav li.nav-item.language a.active img {
  border: 1px solid #fa695f;
  border-radius: 2px;
}
.navbar-area #mobile-menu .icon-close.left, .navbar-area #mobile-menu .icon-close.top {
  position: absolute;
  z-index: 99999;
  padding: 10px;
  cursor: pointer;
  font-size: 18px;
  height: 45px;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.navbar-area #mobile-menu .icon-close.left {
  left: 15px;
  top: 50%;
  background-color: #e0e0e0;
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
  height: 45px;
  width: 45px;
}
.navbar-area #mobile-menu .icon-close.top {
  right: 15px;
  top: 15px;
  background-color: #efefef;
  color: black;
  border: 1px solid #c7c7c7;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.reward-count {
  height: 25px;
  width: 25px;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.reward-count .reward {
  padding: 0;
  width: 100%;
  height: 100%;
  text-align: center;
}
.reward-count .reward:hover {
  color: #0c2041;
}
.reward-count .reward i {
  width: 100%;
  height: 100%;
  font-size: 22px;
}
.reward-count .cover-reward {
  position: absolute;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  margin: 0px;
  padding: 0;
  background-color: #0c2041;
  top: 12px;
  right: 50px;
  text-align: center;
  color: white;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.nav-app {
  display: none !important;
  margin-bottom: 40px;
}

#nav-top {
  position: fixed;
  width: 100%;
  top: 0;
  background-color: #f8fafc;
  box-shadow: 0 0px 3px rgba(0, 0, 0, 0.25);
  z-index: 90;
}
#nav-top .nav-mobile .row {
  padding: 4px;
}
#nav-top .nav-mobile .row .col-1 {
  max-width: 35px !important;
}
#nav-top .nav-mobile .row .col-7 {
  display: flex;
}
#nav-top .nav-mobile .row .col-7 .logo img {
  width: 140px;
}
#nav-top .nav-mobile .row .col-7 .icon i {
  display: block;
  height: 35px;
  width: 55px;
  max-height: 100%;
  max-width: 100%;
  margin: auto;
  font-size: 25px;
  margin-top: 10px;
}
#nav-top .nav-mobile .row .col-4 .acc {
  margin-top: 10px;
}
#nav-top .nav-mobile .row .col-4 .acc span {
  font-family: text-en;
  font-weight: 600;
  font-size: 13px;
  margin-right: 5px;
  cursor: pointer;
}
#nav-top .nav-mobile .row .col-4 .acc span:hover {
  color: #1d68a7;
}

@media only screen and (max-width: 1024px) {
  .col-3.con-logo {
    max-width: 200px !important;
  }
  .col-6.center-menu {
    max-width: 670px !important;
  }
  .con-logo {
    margin-left: 0px !important;
  }
  .main-acc {
    margin-right: 0px !important;
  }
  .navbar-area #menu .col-3.right-btn .profile .dropdown-menu {
    transform: none !important;
    top: 70px !important;
    right: 15px;
    left: inherit !important;
  }
}
@media only screen and (max-width: 992px) {
  .reward-count .cover-reward {
    position: absolute;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    margin: 0px;
    padding: 0;
    background-color: #0c2041;
    top: 12px;
    right: 110px;
    text-align: center;
    color: white;
  }
  .navbar-area #menu .col-3.right-btn {
    max-width: calc(100% - 200px);
  }
  .navbar-area #menu .col-3.right-btn li#nav-phone {
    display: block;
  }
  .new-bk {
    visibility: visible !important;
    transform: translateX(0) !important;
  }
  .open {
    visibility: visible !important;
    transform: translateX(0) !important;
  }
  .nav-app {
    display: block !important;
  }
}
@media only screen and (max-width: 768px) {
  .reward-count .cover-reward {
    position: absolute;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    margin: 0px;
    padding: 0;
    background-color: #0c2041;
    top: 3px;
    right: 110px;
    text-align: center;
    color: white;
    line-height: 20px;
  }
  .reward-count .cover-reward span {
    font-size: 12px;
  }
  .nav-mobile .col-4 {
    display: none !important;
  }
}
@media only screen and (max-width: 576px) {
  .navbar-area #menu .col-3.con-logo {
    height: 60px;
    padding-right: 0;
  }
  .navbar-area #menu .col-3.right-btn {
    height: 60px;
  }
  .navbar-area #menu .col-3.right-btn li.nav-item.language {
    display: none;
  }
  .navbar-area #menu .col-3.right-btn li.nav-item.sign-in {
    margin-left: 0;
  }
  .navbar-area #menu .col-3.right-btn li.nav-item.sign-in a.nav-link.btn-login {
    font-size: 12px;
    padding: 8px 10px;
  }
  .navbar-area #menu .col-3.right-btn li#nav-phone i {
    font-size: 16px;
  }
  .navbar-area #menu .col-3.right-btn .profile .dropdown-menu {
    top: 55px !important;
  }
}
@font-face {
  font-family: "title-en";
  src: url("../fonts/LEMONMILK-Bold.woff2") format("woff2"), url("../fonts/LEMONMILK-Bold.woff") format("woff"), url("../fonts/LEMONMILK-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "text-bold-en";
  src: url("../fonts/LEMONMILK-RegularItalic.woff2") format("woff2"), url("../fonts/LEMONMILK-RegularItalic.woff") format("woff"), url("../fonts/LEMONMILK-RegularItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "text-en";
  src: url("../fonts/Gotham-Book.woff2") format("woff2"), url("../fonts/Gotham-Book.woff") format("woff"), url("../fonts/Gotham-Book.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "title-kh";
  src: url("../fonts/Dangrek.woff2") format("woff2"), url("../fonts/Dangrek.woff") format("woff"), url("../fonts/Dangrek.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "text-bold-kh";
  src: url("../fonts/Kantumruy-Bold.woff2") format("woff2"), url("../fonts/Kantumruy-Bold.woff") format("woff"), url("../fonts/Kantumruy-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "text-light-kh";
  src: url("../fonts/Kantumruy-Light.woff2") format("woff2"), url("../fonts/KantumruyLight.woff") format("woff"), url("../fonts/KantumruyLight.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
.footer-area {
  background-color: #0c2041;
}
.footer-area .foot-logo {
  height: 80px;
}
.footer-area .foot-logo .img-fluid.img-foot-logo {
  height: 80px;
}
.footer-area h4.foot-title {
  font-size: 22px;
  color: rgba(255, 255, 255, 0.7882352941);
  font-weight: 600;
  padding: 15px 0;
}
.footer-area .foot-text {
  color: rgba(255, 255, 255, 0.7882352941);
}
.footer-area .foot-text p.text-body {
  color: rgba(255, 255, 255, 0.7882352941) !important;
  padding-top: 15px;
}
.footer-area .foot-nav a.nav-link, .footer-area .nav-bottom a.nav-link {
  color: rgba(255, 255, 255, 0.7882352941);
  transition: all 0.3s ease-in-out;
}
.footer-area .foot-nav a.nav-link i, .footer-area .nav-bottom a.nav-link i {
  padding-right: 5px;
  transition: all 0.3s ease-in-out;
}
.footer-area .foot-nav a.nav-link:hover, .footer-area .nav-bottom a.nav-link:hover {
  color: #ffffff;
}
.footer-area .foot-nav a.nav-link:hover i, .footer-area .nav-bottom a.nav-link:hover i {
  padding-right: 10px;
}
.footer-area .con-social ul.nav li a {
  color: rgba(255, 255, 255, 0.7882352941);
}
.footer-area .footer-bottom {
  border-top: 1px solid rgba(255, 255, 255, 0.2117647059);
}
.footer-area .footer-bottom .foot-text {
  padding: 10px 0;
  display: inline-block;
}
.footer-area .footer-bottom span {
  color: rgba(255, 255, 255, 0.7882352941);
}

@media only screen and (max-width: 576px) {
  .footer-area p.text-body {
    padding: 0;
    font-size: 12px;
  }
  .footer-area .foot-text, .footer-area a.nav-link {
    font-size: 12px;
  }
  .footer-area .footer-bottom .foot-text {
    padding-bottom: 0;
  }
}
@media only screen and (max-width: 768px) {
  .footer-area .foot-logo {
    padding: 0px;
    text-align: center;
  }
  .footer-area .foot-logo img {
    height: 60px !important;
  }
  .footer-area h4.foot-title {
    font-size: 18px !important;
  }
  .footer-area .footer-bottom {
    text-align: center;
  }
}
@font-face {
  font-family: "title-en";
  src: url("../fonts/LEMONMILK-Bold.woff2") format("woff2"), url("../fonts/LEMONMILK-Bold.woff") format("woff"), url("../fonts/LEMONMILK-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "text-bold-en";
  src: url("../fonts/LEMONMILK-RegularItalic.woff2") format("woff2"), url("../fonts/LEMONMILK-RegularItalic.woff") format("woff"), url("../fonts/LEMONMILK-RegularItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "text-en";
  src: url("../fonts/Gotham-Book.woff2") format("woff2"), url("../fonts/Gotham-Book.woff") format("woff"), url("../fonts/Gotham-Book.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "title-kh";
  src: url("../fonts/Dangrek.woff2") format("woff2"), url("../fonts/Dangrek.woff") format("woff"), url("../fonts/Dangrek.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "text-bold-kh";
  src: url("../fonts/Kantumruy-Bold.woff2") format("woff2"), url("../fonts/Kantumruy-Bold.woff") format("woff"), url("../fonts/Kantumruy-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "text-light-kh";
  src: url("../fonts/Kantumruy-Light.woff2") format("woff2"), url("../fonts/KantumruyLight.woff") format("woff"), url("../fonts/KantumruyLight.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
html:lang(kh) body {
  font-family: "text-light-kh", sans-serif;
}
html:lang(kh) body p, html:lang(kh) body span {
  font-size: 16px;
  font-family: "text-light-kh", sans-serif;
}
html:lang(kh) body span.font,
html:lang(kh) body h1,
html:lang(kh) body h2,
html:lang(kh) body h3,
html:lang(kh) body h4,
html:lang(kh) body h5,
html:lang(kh) body h6 {
  font-family: "title-kh", title-en, sans-serif !important;
}
html:lang(kh) body span.font.title,
html:lang(kh) body h1.title,
html:lang(kh) body h2.title,
html:lang(kh) body h3.title,
html:lang(kh) body h4.title,
html:lang(kh) body h5.title,
html:lang(kh) body h6.title {
  text-transform: capitalize !important;
}
html:lang(kh) body #menu .col-6.center-menu ul.nav li.nav-item a,
html:lang(kh) body #menu .col-6.center-menu ul.nav li.nav-item a.resource ~ .dropdown-menu li a span {
  font-family: "title-kh", text-en;
  font-size: 15px;
}
html:lang(kh) body .swiper-slide .course-box .content-box .content h4 {
  line-height: 32px;
}
html:lang(kh) body .swiper-slide .course-box .content-box .content .sub-title {
  line-height: 24px;
  height: 45px;
}
html:lang(kh) body .swiper-slide .course-box .content-box .content h4 {
  font-size: 18px;
}
html:lang(kh) body .benefit .benefit-content h4.title {
  font-size: 18px;
}
html:lang(kh) body .container-slide .slide-caption h2.title-slide {
  line-height: 55px;
}
html:lang(kh) body #job .body .blog-row .list-job .course-box .content-box .content h2.title {
  height: 65px;
  line-height: 34px;
}
html:lang(kh) body #program .left-sec .title {
  line-height: 32px;
}
@media only screen and (max-width: 1024px) {
  html:lang(kh) body .container-slide .slide-caption h2.title-slide {
    line-height: 36px;
  }
}
@media only screen and (max-width: 768px) {
  html:lang(kh) body .container-slide .slide-caption h2.title-slide {
    line-height: 30px;
    font-size: 20px;
  }
  html:lang(kh) body #job .body .blog-row .list-job .course-box .content-box .content h2.title {
    font-size: 16px;
    height: 50px;
    line-height: 26px;
  }
  html:lang(kh) body #program .left-sec .title {
    font-size: 16px;
    line-height: 26px;
  }
}
@media only screen and (max-width: 576px) {
  html:lang(kh) body .container-slide .slide-caption h2.title-slide {
    line-height: 26px;
    font-size: 18px;
  }
  html:lang(kh) body p, html:lang(kh) body span {
    font-size: 14px;
  }
}

.modal-custom .modal-dialog .modal-content {
  border-radius: 15px;
}
.modal-custom .modal-dialog .modal-content .modal-header-close {
  padding-top: 10px;
}
.modal-custom .modal-dialog .modal-content .modal-header-close button {
  color: #000;
}
.modal-custom .modal-dialog .modal-content .modal-body .form-group {
  padding-bottom: 10px;
}
.modal-custom .modal-dialog .modal-content .modal-body .social-form {
  background-color: #efefef;
  width: 35px;
  margin: 5px;
  border-radius: 50%;
  height: 35px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ffffff;
  transition: all 0.3s ease;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.16);
}
.modal-custom .modal-dialog .modal-content .modal-body .social-form:hover {
  border: 1px solid #0c2041;
}
.modal-custom .modal-dialog .modal-content .modal-body .or {
  text-align: center;
  padding: 0;
  font-weight: 700;
}
.modal-custom .modal-dialog .modal-content .form label {
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 5px;
}
.modal-custom .modal-dialog .modal-content .form label.error {
  color: #c40000;
  font-size: 12px;
  margin: 0;
  line-height: 15px;
  font-weight: unset;
}
.modal-custom .modal-dialog .modal-content .form input {
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  font-size: 13px;
}
.modal-custom .modal-dialog .modal-content .form .register button {
  border: none;
  background-color: #51be95;
  border-radius: 4px;
  padding: 0.375rem 15px;
  color: #fff;
}
.modal-custom .modal-dialog .modal-content .form .register button span {
  font-size: 13px;
}
.modal-custom .modal-dialog .modal-content .form .register button i {
  vertical-align: middle;
}
.modal-custom .modal-dialog.login .form-group {
  margin-bottom: 0;
}

.modal-map .modal-dialog .modal-content {
  border-radius: 15px;
}
.modal-map .modal-dialog .modal-content button {
  position: absolute;
  top: 3%;
  right: 3%;
  z-index: 1;
  font-size: 30px;
}
.modal-map .modal-dialog .modal-content .modal-body {
  height: 300px;
}
.modal-map .modal-dialog .modal-content .modal-body iframe {
  border-radius: 15px;
}

.modal-otp .modal-dialog .modal-content .modal-body .form form {
  display: block;
  padding-top: 10px;
}
.modal-otp .modal-dialog .modal-content .modal-body .form form .form-group {
  padding: 0px 13px;
}
.modal-otp .modal-dialog .modal-content .modal-body .form form .form-group input {
  height: 50px;
  font-size: 25px;
  text-align: center;
}
.modal-otp .modal-dialog .modal-content .modal-body .form form .Continue {
  padding: 30px 0;
}

#exampleModalPayment .modal-dialog .modal-content .modal-body {
  padding: 0 30px 30px 30px;
}
#exampleModalPayment .modal-dialog .modal-content .modal-body .blog {
  box-shadow: 0 5px 5px 5px rgba(0, 0, 0, 0.16);
  padding: 20px 0;
  border-radius: 15px;
}
#exampleModalPayment .modal-dialog .modal-content .modal-body .blog .col-3 {
  padding: 0 7px 0 15px;
}
#exampleModalPayment .modal-dialog .modal-content .modal-body .blog .col-3 .img {
  width: 70px;
  height: 70px;
  overflow: hidden;
}
#exampleModalPayment .modal-dialog .modal-content .modal-body .blog .col-9 {
  padding: 0 15px 0 7px;
}
#exampleModalPayment .modal-dialog .modal-content .modal-body .blog .col-9 h6,
#exampleModalPayment .modal-dialog .modal-content .modal-body .blog .col-9 p {
  margin-bottom: 0;
}
#exampleModalPayment .modal-dialog .modal-content .modal-body .pay-method {
  margin-top: 20px;
}
#exampleModalPayment .modal-dialog .modal-content .modal-body .pay-method ul li a {
  padding: 10px;
}
#exampleModalPayment .modal-dialog .modal-content .modal-body .pay-method ul li a img {
  width: 70px;
}
#exampleModalPayment .modal-dialog .modal-content .modal-body .pay-method .form {
  margin-top: 10px;
}

@media only screen and (max-width: 576px) {
  .modal-custom .modal-body span {
    font-size: 11px;
  }
  .modal-custom .modal-body textarea {
    height: 60px;
  }
}
@font-face {
  font-family: "title-en";
  src: url("../fonts/LEMONMILK-Bold.woff2") format("woff2"), url("../fonts/LEMONMILK-Bold.woff") format("woff"), url("../fonts/LEMONMILK-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "text-bold-en";
  src: url("../fonts/LEMONMILK-RegularItalic.woff2") format("woff2"), url("../fonts/LEMONMILK-RegularItalic.woff") format("woff"), url("../fonts/LEMONMILK-RegularItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "text-en";
  src: url("../fonts/Gotham-Book.woff2") format("woff2"), url("../fonts/Gotham-Book.woff") format("woff"), url("../fonts/Gotham-Book.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "title-kh";
  src: url("../fonts/Dangrek.woff2") format("woff2"), url("../fonts/Dangrek.woff") format("woff"), url("../fonts/Dangrek.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "text-bold-kh";
  src: url("../fonts/Kantumruy-Bold.woff2") format("woff2"), url("../fonts/Kantumruy-Bold.woff") format("woff"), url("../fonts/Kantumruy-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "text-light-kh";
  src: url("../fonts/Kantumruy-Light.woff2") format("woff2"), url("../fonts/KantumruyLight.woff") format("woff"), url("../fonts/KantumruyLight.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
.bubbly-button {
  box-shadow: 0 0px 3px 0px #0c2041;
  background-color: #0c2041;
}

.bubbly-button-green {
  box-shadow: 0 0px 3px 0px #51be95;
  background-color: #51be95;
}

.bubbly-button-blue {
  box-shadow: 0 0px 3px 0px #68b8e7;
  background-color: #68b8e7;
}

.bubbly-button,
.bubbly-button-blue,
.bubbly-button-green {
  display: inline-block;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 4px;
  border: none;
  position: relative;
  transition: transform ease-in 0.1s, transform ease-in 0.3s;
}
.bubbly-button:hover i,
.bubbly-button-blue:hover i,
.bubbly-button-green:hover i {
  transform: translateX(4px);
}
.bubbly-button:focus,
.bubbly-button-blue:focus,
.bubbly-button-green:focus {
  outline: 0;
}
.bubbly-button:before, .bubbly-button:after,
.bubbly-button-blue:before,
.bubbly-button-blue:after,
.bubbly-button-green:before,
.bubbly-button-green:after {
  position: absolute;
  content: "";
  display: block;
  width: 140%;
  height: 100%;
  left: -20%;
  z-index: -1000;
  transition: all ease-in-out 0.5s;
  background-repeat: no-repeat;
}
.bubbly-button:before,
.bubbly-button-blue:before,
.bubbly-button-green:before {
  display: none;
  top: -75%;
  background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%, 15% 15%, 10% 10%, 18% 18%;
}
.bubbly-button:after,
.bubbly-button-blue:after,
.bubbly-button-green:after {
  display: none;
  bottom: -75%;
  background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 10% 10%, 20% 20%;
}
.bubbly-button:active,
.bubbly-button-blue:active,
.bubbly-button-green:active {
  transform: scale(0.9);
  background-color: #08152b;
  box-shadow: 0 2px 25px rgba(255, 0, 130, 0.2);
}
.bubbly-button.animate:before,
.bubbly-button-blue.animate:before,
.bubbly-button-green.animate:before {
  display: block;
  animation: topBubbles ease-in-out 0.75s forwards;
}
.bubbly-button.animate:after,
.bubbly-button-blue.animate:after,
.bubbly-button-green.animate:after {
  display: block;
  animation: bottomBubbles ease-in-out 0.75s forwards;
}

@keyframes topBubbles {
  0% {
    background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%, 40% 90%, 55% 90%, 70% 90%;
  }
  50% {
    background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%, 50% 50%, 65% 20%, 90% 30%;
  }
  100% {
    background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%, 50% 40%, 65% 10%, 90% 20%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}
@keyframes bottomBubbles {
  0% {
    background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%, 70% -10%, 70% 0%;
  }
  50% {
    background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%, 105% 0%;
  }
  100% {
    background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%, 110% 10%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}
/* Bounce In */
.course-box {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.course-box:hover,
.course-box:focus,
.course-box:active {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
  -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
  transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
}

/* Shutter In Vertical */
.card-image a::before {
  content: "";
  position: absolute;
  width: 0%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  transition: all 0.3s ease-in-out;
  z-index: 1;
}
.card-image a:hover::before {
  width: 100%;
}

.card-image .text {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  font-size: 15px;
  transform: translate(-50%, -50%);
  text-align: center;
  cursor: pointer;
  color: #fff;
  font-weight: 500;
  letter-spacing: 1px;
  opacity: 0;
  padding: 0px 10px 0px 10px;
  transition: all 0.5s ease;
  z-index: 2;
}
.card-image:hover .text {
  opacity: 1;
}

@media only screen and (max-width: 1024px) {
  .card-image .text {
    display: none;
  }
}
/*===================Zoom Style==================*/
.image.zoom-in {
  overflow: hidden;
  height: auto;
}
.image.zoom-in img {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.image.zoom-in:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

@font-face {
  font-family: "title-en";
  src: url("../fonts/LEMONMILK-Bold.woff2") format("woff2"), url("../fonts/LEMONMILK-Bold.woff") format("woff"), url("../fonts/LEMONMILK-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "text-bold-en";
  src: url("../fonts/LEMONMILK-RegularItalic.woff2") format("woff2"), url("../fonts/LEMONMILK-RegularItalic.woff") format("woff"), url("../fonts/LEMONMILK-RegularItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "text-en";
  src: url("../fonts/Gotham-Book.woff2") format("woff2"), url("../fonts/Gotham-Book.woff") format("woff"), url("../fonts/Gotham-Book.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "title-kh";
  src: url("../fonts/Dangrek.woff2") format("woff2"), url("../fonts/Dangrek.woff") format("woff"), url("../fonts/Dangrek.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "text-bold-kh";
  src: url("../fonts/Kantumruy-Bold.woff2") format("woff2"), url("../fonts/Kantumruy-Bold.woff") format("woff"), url("../fonts/Kantumruy-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "text-light-kh";
  src: url("../fonts/Kantumruy-Light.woff2") format("woff2"), url("../fonts/KantumruyLight.woff") format("woff"), url("../fonts/KantumruyLight.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@media only screen and (max-width: 992px) {
  #courses .head-sec .title,
  #courses .left-sec .title {
    font-size: 20px !important;
  }
}
@media only screen and (max-width: 992px) {
  #courses .list-course .title {
    font-size: 15px !important;
  }
}
@media only screen and (max-width: 768px) {
  #courses .btn-box a {
    padding: 5px 15px !important;
    font-size: 2vw;
  }
}
@media only screen and (max-width: 992px) {
  #courses .course-detail {
    overflow: hidden;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  #courses .course-detail .detail {
    width: 500px;
  }
  #courses .course-detail::-webkit-scrollbar-track {
    background-color: #F5F5F5;
  }
  #courses .course-detail::-webkit-scrollbar {
    height: 6px;
    background-color: #F5F5F5;
  }
  #courses .course-detail::-webkit-scrollbar-thumb {
    background-color: #000000;
    border-radius: 10px;
  }
}
@font-face {
  font-family: "title-en";
  src: url("../fonts/LEMONMILK-Bold.woff2") format("woff2"), url("../fonts/LEMONMILK-Bold.woff") format("woff"), url("../fonts/LEMONMILK-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "text-bold-en";
  src: url("../fonts/LEMONMILK-RegularItalic.woff2") format("woff2"), url("../fonts/LEMONMILK-RegularItalic.woff") format("woff"), url("../fonts/LEMONMILK-RegularItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "text-en";
  src: url("../fonts/Gotham-Book.woff2") format("woff2"), url("../fonts/Gotham-Book.woff") format("woff"), url("../fonts/Gotham-Book.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "title-kh";
  src: url("../fonts/Dangrek.woff2") format("woff2"), url("../fonts/Dangrek.woff") format("woff"), url("../fonts/Dangrek.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "text-bold-kh";
  src: url("../fonts/Kantumruy-Bold.woff2") format("woff2"), url("../fonts/Kantumruy-Bold.woff") format("woff"), url("../fonts/Kantumruy-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "text-light-kh";
  src: url("../fonts/Kantumruy-Light.woff2") format("woff2"), url("../fonts/KantumruyLight.woff") format("woff"), url("../fonts/KantumruyLight.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@media only screen and (max-width: 992px) {
  #program .head-sec .title,
  #program .left-sec .title {
    font-size: 20px;
  }
  #program .list-course .title {
    font-size: 15px;
  }
}
@media only screen and (max-width: 768px) {
  #program .btn-box a {
    padding: 5px 15px !important;
    font-size: 2vw;
  }
}