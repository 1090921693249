@import"../../variables";
@media only screen and (max-width:992px) {
    #courses {
        .head-sec,
        .left-sec {
            .title {
                font-size: 20px !important;
            }
        }
    }
}

@media only screen and (max-width:992px) {
    #courses {
        .list-course {
            .title {
                font-size: 15px !important;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    #courses {
        .btn-box {
            a {
                padding: 5px 15px !important;
                font-size: 2vw;
            }
        }
    }
}

@media only screen and (max-width:992px) {
    #courses {
        .course-detail {
            overflow: hidden;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
            .detail {
                width: 500px;
            }
        }
        .course-detail::-webkit-scrollbar-track {
            background-color: #F5F5F5;
        }
        .course-detail::-webkit-scrollbar {
            height: 6px;
            background-color: #F5F5F5;
        }
        .course-detail::-webkit-scrollbar-thumb {
            background-color: #000000;
            border-radius: 10px;
        }
    }
}