@import "../variables";

.footer-area {
    background-color: $main;

    .foot-logo {
        height: 80px;

        .img-fluid.img-foot-logo {
            height: 80px;
        }
    }

    h4.foot-title {
        font-size: 22px;
        color: #ffffffc9;
        font-weight: 600;
        padding: 15px 0;
    }

    .foot-text {
        color: #ffffffc9;

        p.text-body {
            color: #ffffffc9 !important;
            padding-top: 15px;
        }
    }

    .foot-nav, .nav-bottom {
        a.nav-link {
            color: #ffffffc9;
            transition: all 0.3s ease-in-out;

            & i {
                padding-right: 5px;
                transition: all 0.3s ease-in-out;
            }

            &:hover {
                color: #ffffff;

                & i {
                    padding-right: 10px;
                }
            }
        }
    }

    .con-social ul.nav li a {
        color: #ffffffc9;
    }

    .footer-bottom {
        border-top: 1px solid #ffffff36;

        .foot-text {
            padding: 10px 0;
            display: inline-block;
        }

        span {
            color: #ffffffc9;
        }

    }
}

@media only screen and (max-width: 576px) {
    .footer-area {
        .row {
            .con-social {
                .nav {

                }
            }
        }
        p.text-body{
            padding: 0;
            font-size: 12px
        }
        .foot-text,a.nav-link {
            font-size: 12px;
        }
        .footer-bottom .foot-text{
            padding-bottom: 0;
        }
    }
}

@media only screen and (max-width: 768px) {
    .footer-area {
        .foot-logo {
            padding: 0px;
            text-align: center;

            img {
                height: 60px !important;
            }
        }

        h4.foot-title {
            font-size: 18px !important;
        }
        .footer-bottom {
            text-align: center;
        }
    }
}
