@import'../variables';
html:lang(kh) body{
    font-family: 'text-light-kh', sans-serif;
    p,span{
        font-size: 16px;
        font-family: 'text-light-kh', sans-serif;
    }
    span.font,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6
    {
        font-family: "title-kh", title-en , sans-serif!important ;
        &.title {
            text-transform: capitalize!important;
        }
    }
    #menu .col-6.center-menu ul.nav li.nav-item a,
    #menu .col-6.center-menu ul.nav li.nav-item a.resource ~ .dropdown-menu li a span{
        font-family: "title-kh", text-en;
        font-size: 15px;
    }
    .swiper-slide .course-box .content-box {
        .content {
            h4{
                line-height: 32px;
            }
            .sub-title{
                line-height: 24px;
                height: 45px;
            }
        }
    }
    //program
    .swiper-slide .course-box .content-box .content{
        h4{
            font-size: 18px;
        }
    }
    .benefit .benefit-content h4.title{
        font-size: 18px;
    }

//Home Page
    .container-slide .slide-caption h2.title-slide{
        line-height: 55px;
    }
    //Job Page
    #job .body .blog-row .list-job .course-box .content-box .content h2.title{
        height: 65px;
        line-height: 34px;
    }
    //Program Page
    #program {
        .left-sec {
            .title {
                line-height: 32px;
            }
        }
    }
    @media only screen and (max-width: 1024px) {
        .container-slide .slide-caption h2.title-slide{
            line-height: 36px;

        }
    }
    @media only screen and (max-width: 768px) {
        .container-slide .slide-caption h2.title-slide{
            line-height: 30px;
            font-size: 20px;
        }
        #job .body .blog-row .list-job .course-box .content-box .content h2.title {
            font-size: 16px;
            height: 50px;
            line-height: 26px;
        }
        #program {
            .left-sec {
                .title {
                    font-size: 16px;
                    line-height: 26px;

                }
            }
        }

    }
    @media only screen and (max-width: 576px) {
        .container-slide .slide-caption h2.title-slide{
            line-height: 26px;
            font-size: 18px;
        }
        p,span{
            font-size: 14px;

        }
    }
}
