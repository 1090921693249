/*===================Zoom Style==================*/

.image.zoom-in {
    overflow: hidden;
    height: auto;
    img {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: .3s ease-in-out;
        transition: .3s ease-in-out;
    }
    &:hover img {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
        -webkit-transition: .3s ease-in-out;
        transition: .3s ease-in-out;
    }
}