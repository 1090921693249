@import "../variables";
@import "responsive-header";

.navbar-area {
    position: -webkit-sticky;
    position: sticky;
    width: 100%;
    top: 0;
    background-color: #f8fafc;
    box-shadow: 0 0px 3px rgb(0 0 0 / 25%);
    z-index: 999;

    #menu {
        .col-3.con-logo {
            flex: 1;
            max-width: 230px;
            height: 80px;

            .img-fluid.img-logo {
                max-width: 100%;
                height: 100%;
                padding: 10px 0;
            }
        }

        .col-6.center-menu {
            flex: 1;
            max-width: calc(100% - 430px);

            ul.nav {
                align-items: center;
                height: 80px;

                li.nav-item {
                    position: relative;
                    padding: 5px 9px;

                    &.active {
                        a.nav-link {
                            position: relative;

                            &::before {
                                content: "";
                                position: absolute;
                                bottom: -3px;
                                width: 100%;
                                height: 2px;
                                background-color: $red-main;
                            }
                        }
                    }

                    a {
                        padding: 0;
                        font-family: "text-en";
                        font-weight: 600;
                        font-size: 13px;

                        &.resource {
                            & ~ .dropdown-menu {
                                transform: unset !important;
                                top: 35px !important;
                                left: -50px !important;
                                min-width: 140px;
                                box-shadow: -1px -3px 10px 1px rgb(0 0 0 / 16%);
                                border: unset;
                                border-radius: 8px;
                                padding: 15px 10px;

                                li {
                                    &.active {
                                        background-color: #e9ecef;
                                    }
                                }

                                li a {
                                    font-weight: 100;
                                    padding: 5px 15px;

                                    span {
                                        padding-left: 5px;
                                    }
                                }

                                &::before {
                                    content: "";
                                    border-color: #707070;
                                    top: -3px;
                                    left: 45%;
                                    position: absolute;
                                    border-top: 0;
                                    border-right: 10px solid transparent;
                                    border-bottom: 5px solid #fff;
                                    border-left: 10px solid transparent;
                                }
                            }
                        }
                    }
                }
            }
        }

        .col-3.right-btn {
            padding-left: 0;
            flex: 1;
            max-width: 200px;
            height: 80px;
            align-items: center;
            display: flex;
            justify-content: flex-end;

            .language {

                a {
                    &.active {
                        img {
                            border: 1px solid #fa695f;
                            border-radius: 2px;
                        }
                    }
                }
            }

            li.nav-item {
                margin-left: 15px;

                &.language {
                    height: 25px;
                    width: 25px;
                    border-radius: 50%;
                    overflow: hidden;
                    display: flex;
                    align-items: center;

                    &:first-child {
                        margin-left: 0;
                    }
                }
            }

            .icon {
                .sub-icon {
                    img {
                        width: 30px;
                        height: 30px;
                    }
                }
            }

            .profile {
                .dropdown-button {
                    img {
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        margin-right: 0;
                    }

                    span {
                        margin-right: 15px;
                    }

                    &::after {
                        display: none;
                        //margin-left: 0.255em;
                        //vertical-align: 0.255em;
                        //content: "";
                        //border-top: 0.3em solid;
                        //border-right: 0.3em solid transparent;
                        //border-bottom: 0;
                        //border-left: 0.3em solid transparent;
                        //position: absolute;
                        //top: 35px;
                        //right: 0px;
                    }
                }

                .dropdown-menu {
                    transform: translate3d(86px, 68px, 0px) !important;
                    box-shadow: -1px -3px 10px 1px rgb(0 0 0 / 16%);
                    border: unset;
                    border-radius: 8px;
                    padding: 15px 0;

                    &::before {
                        content: "";
                        border-color: #707070;
                        top: -3px;
                        left: 45%;
                        position: absolute;
                        border-top: 0;
                        border-right: 10px solid transparent;
                        border-bottom: 5px solid #fff;
                        border-left: 10px solid transparent;

                    }

                    li {
                        a {
                            &.dropdown-item {
                                padding: 5px 10px;

                                i {
                                    width: 15px;
                                }

                                span {
                                    padding-left: 5px;
                                }
                            }

                        }
                    }
                }
            }

            .profile {
                .pro {
                    li {
                        &:last-child {
                            border-top: 2px solid #E2DFDF;

                            button {
                                i {
                                    width: 20px;
                                }

                                border: none;
                                width: 100%;
                                background-color: unset;
                                text-align: left;
                                padding: 5px 0 0 10px;
                                font-weight: 600;
                            }
                        }
                    }
                }

                .acc {
                    li {
                        &:nth-child(2) {
                            border-top: 2px solid #E2DFDF;

                            button {
                                border: none;
                                width: 100%;
                                background-color: unset;
                                text-align: left;
                                padding: 7px 0 0 15px;
                                font-weight: 600;
                            }
                        }
                    }
                }
            }

            a.nav-link.btn-login {
                background-color: $main;
                border-radius: 4px;
                font-size: 14px;
                text-transform: capitalize;
                color: #fff;
            }

            button.btn {
                padding: 10px 25px;
                background-color: $green-main;
                border-radius: 10px;
                color: #fff;

                span {
                    font-size: 16px;
                }

                i {
                    padding-left: 15px;
                    font-size: 30px;
                    vertical-align: middle;
                }
            }

            li#nav-phone {
                display: none;

                i {
                    background-color: #0c204129;
                    padding: 10px 15px;
                    border-radius: 4px;
                    font-size: 18px;
                    text-transform: capitalize;
                    color: #000;
                    cursor: pointer;


                }
            }
        }

    }

    .show {
        #mobile-menu {
            display: block;
            right: 15px;
            opacity: 1;
            visibility: visible;
        }
    }

    #mobile-menu {
        opacity: 0;
        visibility: hidden;
        position: fixed;
        right: -15%;
        top: 0;
        height: 100vh;
        width: 100%;
        background-color: #f2f2f2eb;
        z-index: 9999;
        -webkit-transition: 0.5s;
        -moz-transition: 0.5s;
        -ms-transition: 0.5s;
        -o-transition: 0.5s;
        transition: 0.5s;

        .list-menu {
            position: relative;
            margin: 10%;
            width: 80%;
            background: #e8e8e8;
            height: 80%;
            padding: 15px;
            border-radius: 20px;
            overflow-y: scroll;

            &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                background-color: #F5F5F5;
            }

            &::-webkit-scrollbar {
                width: 0;
                background-color: transparent;
            }

            &::-webkit-scrollbar-thumb {
                background-color: transparent;
            }

            ul.nav {
                li.nav-item {
                    position: relative;

                    a.nav-link {
                        font-size: 18px;

                        &.btn-login {
                            background-color: $main;
                            color: white;
                            margin-top: 15px;
                            border-radius: 4px;
                            width: 100%;
                        }
                    }

                    &.language {
                        height: 25px;
                        width: 25px;
                        border-radius: 50%;
                        overflow: hidden;
                        display: flex;
                        align-items: center;
                        margin: 0 10px;

                        a {
                            &.active {
                                img {
                                    border: 1px solid #fa695f;
                                    border-radius: 2px;
                                }
                            }
                        }

                    }
                }
            }


        }

        .icon-close {
            &.left, &.top {
                position: absolute;
                z-index: 99999;
                padding: 10px;
                cursor: pointer;
                font-size: 18px;
                height: 45px;
                width: 45px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;

            }

            &.left {
                left: 15px;
                top: 50%;
                background-color: #e0e0e0;
                padding: 10px;
                cursor: pointer;
                font-size: 14px;
                height: 45px;
                width: 45px;


            }

            &.top {
                right: 15px;
                top: 15px;
                background-color: #efefef;
                color: black;

                border: 1px solid #c7c7c7;
            }
        }
    }
}

//switch
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

.reward-count{
    height: 25px;
    width: 25px;
    overflow: hidden;
    display: flex;
    align-items: center;
    .reward{
        padding: 0;
        width: 100%;
        height: 100%;
        text-align: center;
        &:hover{
            color: #0c2041;
        }
        i{
            width: 100%;
            height: 100%;
            font-size: 22px;
        }
    }
    .cover-reward{
        position: absolute;
        height: 22px;
        width: 22px;
        border-radius: 50%;
        margin: 0px;
        padding: 0;
        background-color: #0c2041;
        top: 12px;
        right: 50px;
        text-align: center;
        color: white;
    }
}

input:checked + .slider {
    background-color: #2196F3;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

// nav app
.nav-app {
    display: none !important;
    margin-bottom: 40px;
}

#nav-top {
    position: fixed;
    width: 100%;
    top: 0;
    background-color: #f8fafc;
    box-shadow: 0 0px 3px rgb(0 0 0 / 25%);
    z-index: 90;

    .nav-mobile {
        .row {
            padding: 4px;

            .col-1 {
                max-width: 35px !important;
            }

            .col-7 {
                display: flex;

                .logo {
                    img {
                        width: 140px;
                    }
                }

                .icon {
                    i {
                        display: block;
                        height: 35px;
                        width: 55px;
                        max-height: 100%;
                        max-width: 100%;
                        margin: auto;
                        font-size: 25px;
                        margin-top: 10px;
                    }
                }
            }

            .col-4 {
                .acc {
                    margin-top: 10px;

                    span {
                        font-family: text-en;
                        font-weight: 600;
                        font-size: 13px;
                        margin-right: 5px;
                        cursor: pointer;

                        &:hover {
                            color: #1d68a7;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .col-3.con-logo {
        max-width: 200px !important;
    }
    .col-6.center-menu {
        max-width: calc(1100px - 430px) !important;
    }
    .con-logo {
        margin-left: 0px !important;
    }
    .main-acc {
        margin-right: 0px !important;
    }
    .navbar-area #menu .col-3.right-btn .profile .dropdown-menu {
        transform: none !important;
        top: 70px !important;
        right: 15px;
        left: inherit !important;
    }
}

@media only screen and (max-width: 992px) {
    .reward-count .cover-reward {
        position: absolute;
        height: 22px;
        width: 22px;
        border-radius: 50%;
        margin: 0px;
        padding: 0;
        background-color: #0c2041;
        top: 12px;
        right: 110px;
        text-align: center;
        color: white;
    }
    .navbar-area {
        #menu {
            .col-3.right-btn {
                max-width: calc(100% - 200px);

                li#nav-phone {
                    display: block;
                }
            }
        }
    }
    .new-bk {
        visibility: visible !important;
        transform: translateX(0) !important;
    }
    .open {
        visibility: visible !important;
        transform: translateX(0) !important;
    }

    .nav-app {
        display: block !important;
    }
}

@media only screen and (max-width: 768px) {
    .reward-count .cover-reward {
        position: absolute;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        margin: 0px;
        padding: 0;
        background-color: #0c2041;
        top: 3px;
        right: 110px;
        text-align: center;
        color: white;
        line-height: 20px;
        span{
            font-size: 12px;
        }
    }
    .nav-mobile {
        .col-4 {
            display: none !important;
        }
    }
}

@media only screen and (max-width: 576px) {
    .navbar-area #menu {
        .col-3.con-logo {
            height: 60px;
            padding-right: 0;
        }

        .col-3.right-btn {
            height: 60px;

            li.nav-item.language {
                display: none;
            }

            li.nav-item.sign-in {
                margin-left: 0;
                a.nav-link.btn-login{
                    font-size: 12px;
                    padding: 8px 10px;
                }
            }

            li#nav-phone i {
                font-size: 16px;
            }

            .profile .dropdown-menu {
                top: 55px !important;
            }
        }
    }
}


