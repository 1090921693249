@import"../../variables";
@media only screen and (max-width:1024px) {

}
@media only screen and (max-width:992px) {
    #program {
        .head-sec,
        .left-sec {
            .title {
                font-size: 20px;
            }
        }
        .list-course {
            .title {
                font-size: 15px;
            }
        }
        //.course-detail {
        //    overflow: hidden;
        //    overflow-x: auto;
        //    -webkit-overflow-scrolling: touch;
        //    //.detail {
        //    //    width: 500px;
        //    //}
        //}
        //.course-detail::-webkit-scrollbar-track {
        //    background-color: #F5F5F5;
        //}
        //.course-detail::-webkit-scrollbar {
        //    height: 6px;
        //    background-color: #F5F5F5;
        //}
        //.course-detail::-webkit-scrollbar-thumb {
        //    background-color: #000000;
        //    border-radius: 10px;
        //}
    }
}

@media only screen and (max-width: 768px) {
    #program {
        .btn-box {
            a {
                padding: 5px 15px !important;
                font-size: 2vw;
            }
        }
    }
}
