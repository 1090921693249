/* Shutter In Vertical */

.card-image a {
    &::before {
        content: "";
        position: absolute;
        width: 0%;
        height: 100%;
        background-color: rgb(0 0 0/40%);
        transition: all 0.3s ease-in-out;
        z-index: 1;
    }
    &:hover {
        &::before {
            width: 100%;
        }
    }
}

.card-image {
    .text {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-right: -50%;
        font-size: 15px;
        transform: translate(-50%, -50%);
        text-align: center;
        cursor: pointer;
        color: #fff;
        font-weight: 500;
        letter-spacing: 1px;
        opacity: 0;
        padding: 0px 10px 0px 10px;
        transition: all 0.5s ease;
        z-index: 2;
    }
    &:hover {
        .text {
            opacity: 1;
        }
    }
}
@media only screen and (max-width:1024px) {
    .card-image {
        .text {
            display: none;
        }
    }
}
